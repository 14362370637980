<script>
  import { onMount } from "svelte";
  import { gsap } from "gsap";
  import Scrambler from "scrambling-letters";
  import earth from "../../assets/images/themes/earth.png";
  import placeholder from "../../assets/images/themes/placeholder.png";
  import bastille from "../../assets/images/themes/bastille.png";
  import summer from "../../assets/images/themes/summer.png";
  import web from "../../assets/images/themes/web.png";
  import cny from "../../assets/images/themes/lantern.png";
  import fallout from "../../assets/images/themes/fallout.png";
  import shamrock from "../../assets/images/themes/shamrock.png";
  import christmas from "../../assets/images/themes/christmas.png";
  import earthlogo from "../../icons/elie-logo-stpatrick.svg";
  import bastillelogo from "../../icons/elie-logo-bastille.svg";
  import summerlogo from "../../icons/elie-logo-summer.svg";
  import weblogo from "../../icons/elie-logo-halloween.svg";
  import cnylogo from "../../icons/elie-logo-cnnewyear.svg";
  import shamrocklogo from "../../icons/elie-logo-stpatrick.svg";
  import christmaslogo from "../../icons/elie-logo-winter.svg";
  import normalLogo from "../../icons/elie-logo.svg";

  const currentDate = new Date();

  let themes = {
    "theme-admin": {
      image: fallout,
      logo: summerlogo,
      animation: createGradientLine,
      date: false,
    },
    "theme-default": {
      image: placeholder,
      logo: normalLogo,
      animation: createGradientLine,
      date: false,
    },
    "theme-earth": {
      image: earth,
      logo: earthlogo,
      animation: createEarth,
      date: new Date(currentDate.getFullYear(), 3, 23),
    },
    "theme-bastille": {
      image: bastille,
      logo: bastillelogo,
      animation: createFirework,
      date: new Date(currentDate.getFullYear(), 6, 14),
    },
    "theme-summer": {
      image: summer,
      logo: summerlogo,
      animation: createFirework,
      date: new Date(currentDate.getFullYear(), 6, 30),
    },
    "theme-halloween": {
      image: web,
      logo: weblogo,
      animation: createHalloween,
      date: new Date(currentDate.getFullYear(), 9, 20),
    },
    "theme-chineseny": {
      image: cny,
      logo: cnylogo,
      animation: createFirework,
      date: new Date(currentDate.getFullYear(), 0, 29),
    },
    "theme-stpatricks": {
      image: shamrock,
      logo: shamrocklogo,
      animation: createClover,
      date: new Date(currentDate.getFullYear(), 2, 17),
    },
    "theme-winter": {
      image: christmas,
      logo: christmaslogo,
      animation: createSnow,
      date: new Date(currentDate.getFullYear(), 11, 25),
    },
  };

  export let path;
  export let title;
  let adminMode;
  let currentTheme;

  // Function to set theme
  function setTheme(themeName) {
    if (themeName in themes) {
      document.documentElement.classList.remove(
        "theme-admin",
        "theme-default",
        "theme-earth",
        "theme-bastille",
        "theme-summer",
        "theme-halloween",
        "theme-chineseny",
        "theme-stpatricks",
        "theme-winter"
      );
      if (typeof localStorage !== 'undefined') {
      localStorage.setItem("adminTheme", themeName);
      }
      document.documentElement.classList.add(themeName);
      currentTheme = themeName;
      const image = document.getElementById("themeImage");
      const logo = document.getElementById("themeLogo");

      // Set the image and logo source based on the theme
      image.src = themes[themeName].image.src;
      logo.src = themes[themeName].logo.src;
    }
  }

  let viewBoxWidth = 0;
  let viewBoxHeight = 0;
  let isPaused = false;
  let lineTimelines = [];
  let circlesGroups = null;

  function createFirework(container, end = false, x1, y1, gradient) {
    // Create, animate, and insert gradient lines
    let svgNS = "http://www.w3.org/2000/svg";
    let line = document.createElementNS(svgNS, "line");

    let lineMaxHeight = 120; // Changed variable name for vertical lines
    let lineMinHeight = 10; // Changed variable name for vertical lines

    if (!x1) x1 = Math.round(Math.random() * (viewBoxWidth - 0) + 0);
    if (!y1) y1 = Math.round(Math.random() * (viewBoxHeight - 0) + 0);
    if (!gradient) gradient = Math.round(Math.random() * (3 - 1) + 1);

    let lineheight = Math.round(
      Math.random() * (lineMaxHeight - lineMinHeight) + lineMinHeight
    );

    // Create the SVG element with reversed vertical coordinates
    line.setAttributeNS(null, "x1", x1.toString());
    line.setAttributeNS(null, "y1", (y1 + lineheight).toString());
    line.setAttributeNS(null, "x2", (x1 + 0.0001).toString());
    line.setAttributeNS(null, "y2", y1.toString());
    line.setAttributeNS(null, "stroke-width", "5");
    line.setAttributeNS(null, "class", "line");
    line.setAttributeNS(null, "stroke-linecap", "round");
    line.setAttributeNS(null, "stroke-dasharray", "0");
    line.setAttributeNS(null, "stroke", "yellow"); // Set stroke color to yellow
    line.setAttributeNS(null, "style", "opacity:0");

    let dashArray = [
      "0 10 0 10 500",
      "0 10 500",
      lineheight - 30 + " 10 0 10 0 500",
      lineheight - 30 + "10 0 500",
      "0",
      "0",
      "0",
      "0",
    ];

    line.setAttributeNS(null, "stroke-dasharray", randomElement(dashArray));

    // Create the Timeline
    let tl = gsap.timeline({
      onComplete: function () {
        // remove the line from the DOM at the end of the animation
        line.remove();
        lineTimelines.splice(lineTimelines.indexOf(tl), 1);
      },
    });

    // Add line timelines to the array to access it out
    lineTimelines.push(tl);

    // Define the animation
    let time = Math.random() * (3 - 1) + 1;
    tl.to(line, time, {
      opacity: 1,
      y: -y1,
      ease: "power0.easeNone",
    }).to(line, time, {
      scaleY: 0.1,
      scaleX: 2,
      opacity: 0,
    });

    // Insert the line in the DOM
    container.insertBefore(line, container.firstChild);
  }

  function createSnow(container, end = false, x, y) {
    let svgNS = "http://www.w3.org/2000/svg";
    let group = document.createElementNS(svgNS, "g");

    // Adjust scale factor to adjust the size of the snow
    let scaleFactor = 0.05;

    if (!x) x = Math.round(Math.random() * (viewBoxWidth - 0) + 0);
    if (!y) y = Math.round(Math.random() * (viewBoxHeight - 0) + 0);

    let path = document.createElementNS(svgNS, "path");
    let pathDefinition =
      "M221.83 150.57a6 6 0 0 1-4.4 7.26l-26.62 6.54l7 26.08a6 6 0 0 1-4.24 7.35a6.4 6.4 0 0 1-1.55.2a6 6 0 0 1-5.8-4.45L178.27 164L134 138.39v51.13l22.24 22.24a6 6 0 1 1-8.48 8.48L128 200.49l-19.76 19.75a6 6 0 0 1-8.48-8.48L122 189.52v-51.13L77.73 164l-7.93 29.6A6 6 0 0 1 64 198a6.4 6.4 0 0 1-1.55-.2a6 6 0 0 1-4.24-7.35l7-26.08l-26.62-6.54a6 6 0 0 1 2.86-11.66l30.23 7.43L116 128l-44.34-25.6l-30.23 7.43A5.88 5.88 0 0 1 40 110a6 6 0 0 1-1.43-11.83l26.62-6.54l-7-26.08a6 6 0 1 1 11.59-3.1l7.93 29.6L122 117.61V66.48L99.76 44.24a6 6 0 0 1 8.48-8.48L128 55.51l19.76-19.75a6 6 0 0 1 8.48 8.48L134 66.48v51.13l44.27-25.56l7.93-29.6a6 6 0 1 1 11.59 3.1l-7 26.08l26.62 6.54A6 6 0 0 1 216 110a5.88 5.88 0 0 1-1.43-.17l-30.23-7.43L140 128l44.34 25.6l30.23-7.43a6 6 0 0 1 7.26 4.4";

    // Append the provided SVG path to the path element
    path.setAttributeNS(null, "d", pathDefinition);
    path.setAttributeNS(null, "fill", "white");

    // Scale the group to adjust the size of the snow
    group.setAttributeNS(
      null,
      "transform",
      "translate(" + x + "," + -y + ") scale(" + scaleFactor + ")"
    );

    group.appendChild(path);

    let tl = gsap.timeline({
      onComplete: function () {
        group.remove();
        lineTimelines.splice(lineTimelines.indexOf(tl), 1);
      },
    });

    lineTimelines.push(tl);

    let time = Math.random() * (15 - 12) + 12;
    tl.to(group, time, {
      opacity: 1,
      rotation: gsap.utils.random(0, 180),
      y: viewBoxHeight,
      ease: "power0.easeNone",
    });

    container.appendChild(group);

    if (!end) {
      let offset = Math.round(Math.random() * (30 - -30) + -30);
      if (Math.random() * 10 > 3) createSnow(container, true, x + offset, y);
    }
  }
  function createClover(container, end = false, x, y) {
    let svgNS = "http://www.w3.org/2000/svg";
    let group = document.createElementNS(svgNS, "g");

    // Adjust scale factor to adjust the size of the clover
    let scaleFactor = 0.05;

    if (!x) x = Math.round(Math.random() * (viewBoxWidth - 0) + 0);
    if (!y) y = Math.round(Math.random() * (viewBoxHeight - 0) + 0);

    let path = document.createElementNS(svgNS, "path");
    let pathDefinition =
      "M216.6 49.9C205.1 38.5 189.5 32 173.3 32C139.4 32 112 59.4 112 93.3v4.9c0 12 3.3 23.7 9.4 34l18.8 31.3c1.1 1.8 1.2 3.1 1 4.2c-.2 1.2-.8 2.5-2 3.6s-2.4 1.8-3.6 2c-1 .2-2.4.1-4.2-1l-31.3-18.8c-10.3-6.2-22-9.4-34-9.4h-4.8C27.4 144 0 171.4 0 205.3c0 16.2 6.5 31.8 17.9 43.3l1.2 1.2c3.4 3.4 3.4 9 0 12.4l-1.2 1.2C6.5 274.9 0 290.5 0 306.7C0 340.6 27.4 368 61.3 368h4.9c12 0 23.7-3.3 34-9.4l31.3-18.8c1.8-1.1 3.1-1.2 4.2-1c1.2.2 2.5.8 3.6 2s1.8 2.4 2 3.6c.2 1 .1 2.4-1 4.2l-18.8 31.3c-6.2 10.3-9.4 22-9.4 34v4.9c0 33.8 27.4 61.3 61.3 61.3c16.2 0 31.8-6.5 43.3-17.9l1.2-1.2c3.4-3.4 9-3.4 12.4 0l1.2 1.2c11.5 11.5 27.1 17.9 43.3 17.9c33.8 0 61.3-27.4 61.3-61.3v-4.9c0-12-3.3-23.7-9.4-34l-18.8-31.3c-1.1-1.8-1.2-3.1-1-4.2c.2-1.2.8-2.5 2-3.6s2.4-1.8 3.6-2c1-.2 2.4-.1 4.2 1l31.3 18.8c10.3 6.2 22 9.4 34 9.4h4.9c33.8 0 61.3-27.4 61.3-61.3c0-16.2-6.5-31.8-17.9-43.3l-1.2-1.2c-3.4-3.4-3.4-9 0-12.4l1.2-1.2c11.5-11.5 17.9-27.1 17.9-43.3c0-33.8-27.4-61.3-61.3-61.3H382c-12 0-23.7 3.3-34 9.4l-31.3 18.8c-1.8 1.1-3.1 1.2-4.2 1c-1.2-.2-2.5-.8-3.6-2s-1.8-2.4-2-3.6c-.2-1-.1-2.4 1-4.2l18.8-31.3c6.2-10.3 9.4-22 9.4-34v-4.8C336 59.4 308.6 32 274.7 32c-16.2 0-31.8 6.5-43.3 17.9l-1.2 1.2c-3.4 3.4-9 3.4-12.4 0z";

    // Append the provided SVG path to the path element
    path.setAttributeNS(null, "d", pathDefinition);
    path.setAttributeNS(null, "fill", "#009900");

    // Scale the group to adjust the size of the clover
    group.setAttributeNS(
      null,
      "transform",
      "translate(" + x + "," + -y + ") scale(" + scaleFactor + ")"
    );

    group.appendChild(path);

    let tl = gsap.timeline({
      onComplete: function () {
        group.remove();
        lineTimelines.splice(lineTimelines.indexOf(tl), 1);
      },
    });

    lineTimelines.push(tl);

    let time = Math.random() * (15 - 12) + 12;
    tl.to(group, time, {
      opacity: 1,
      rotation: gsap.utils.random(0, 180),
      y: viewBoxHeight,
      ease: "power0.easeNone",
    });

    container.appendChild(group);
  }
  function createHalloween(container, end = false, x, y) {
    let svgNS = "http://www.w3.org/2000/svg";
    let group = document.createElementNS(svgNS, "g");

    // Adjust scale factor to adjust the size of the halloween
    let scaleFactor = 1.5;

    if (!x) x = Math.round(Math.random() * (viewBoxWidth - 0) + 0);
    if (!y) y = Math.round(Math.random() * (viewBoxHeight - 0) + 0);

    let path = document.createElementNS(svgNS, "path");
    let pathDefinition =
      "M6.5 6c.97 0 1.87.5 2.61 1.38c.55-.59 1.2-1.02 1.89-1.23V4a2 2 0 0 1 2-2h2v2h-2v2.15c.69.21 1.34.64 1.89 1.23C15.63 6.5 16.53 6 17.5 6C20 6 22 9.36 22 13.5c0 4.14-2 7.5-4.5 7.5c-.97 0-1.87-.5-2.61-1.38C14.08 20.5 13.08 21 12 21s-2.08-.5-2.89-1.38C8.37 20.5 7.47 21 6.5 21C4 21 2 17.64 2 13.5C2 9.36 4 6 6.5 6M9 10l-1.25 2.25h2.5zm6 0l-1.25 2.25h2.5zm-7 7h2l1-1l1 1h2l1-1l1 1l1.5-3l-3.58.62L13 16l-1-1h-2l-1 1l-1-1l-2-1z";

    // Append the provided SVG path to the path element
    path.setAttributeNS(null, "d", pathDefinition);
    path.setAttributeNS(null, "fill", "orange");

    // Scale the group to adjust the size of the halloween
    group.setAttributeNS(
      null,
      "transform",
      "translate(" + x + "," + -y + ") scale(" + scaleFactor + ")"
    );

    group.appendChild(path);

    let tl = gsap.timeline({
      onComplete: function () {
        group.remove();
        lineTimelines.splice(lineTimelines.indexOf(tl), 1);
      },
    });

    lineTimelines.push(tl);

    let time = Math.random() * (15 - 12) + 12;
    tl.to(group, time, {
      opacity: 1,
      rotation: gsap.utils.random(0, 180),
      y: viewBoxHeight,
      ease: "power0.easeNone",
    });

    container.appendChild(group);
  }
  function createEarth(container, end = false, x, y) {
    let svgNS = "http://www.w3.org/2000/svg";
    let group = document.createElementNS(svgNS, "g");

    // Adjust scale factor to adjust the size of the earth
    let scaleFactor = 0.02;

    if (!x) x = Math.round(Math.random() * (viewBoxWidth - 0) + 0);
    if (!y) y = Math.round(Math.random() * (viewBoxHeight - 0) + 0);

    let path = document.createElementNS(svgNS, "path");
    let pathDefinition =
      "M131.652 839.52c-7.46-17.981-16.747-35.855-19.464-55.253c-35.786-250.118 168.278-452.905 353.494-529.928c185.214-77.022 398.471 45.498 524.903-86.019c31.587-35.667 63.736-69.443 111.762-69.694c20.018.993 36.688 10.401 45.207 26.999c158.034 323.688-67.753 707.493-322.728 843.865c-161.812 79.98-329.249 97.576-485.976 43.323c-43.582-10.338-78.515-55.918-123.691-56.51c-31.154 17.48-57.029 74.434-78.17 105.797c-24.625 42.738-73.658 54.038-108.937 16.64c-102.899-101.406 110.943-191.407 103.6-239.22m137.506-41.44c22.099 17.854 53.978 12.793 70.95-5.022C475.799 628.65 659.535 556.522 859.36 561.999c29.444 1.709 50.848-22.136 52.74-47.718c.592-30.381-22.788-50.927-48.975-52.742c-241.513-13.425-451.044 93.065-598.993 264.964c-18.738 23.4-15.336 54.535 5.026 71.577";

    // Append the provided SVG path to the path element
    path.setAttributeNS(null, "d", pathDefinition);
    path.setAttributeNS(null, "fill", "#009900");

    // Scale the group to adjust the size of the earth
    group.setAttributeNS(
      null,
      "transform",
      "translate(" + x + "," + -y + ") scale(" + scaleFactor + ")"
    );

    group.appendChild(path);

    let tl = gsap.timeline({
      onComplete: function () {
        group.remove();
        lineTimelines.splice(lineTimelines.indexOf(tl), 1);
      },
    });

    lineTimelines.push(tl);

    let time = Math.random() * (15 - 12) + 12;
    tl.to(group, time, {
      opacity: 1,
      rotation: gsap.utils.random(0, 180),
      y: viewBoxHeight,
      ease: "power0.easeNone",
    });

    container.appendChild(group);
  }

  function createGradientLine(container, end = false, x1, y1, gradient) {
    // Create, animate, and insert gradient lines
    let svgNS = "http://www.w3.org/2000/svg";
    let line = document.createElementNS(svgNS, "line");

    let lineMaxWidth = 120;
    let lineMinWidth = 10;

    if (!x1) x1 = Math.round(Math.random() * (viewBoxWidth - 0) + 0);
    if (!y1) y1 = Math.round(Math.random() * (viewBoxHeight - 0) + 0);
    if (!gradient) gradient = Math.round(Math.random() * (3 - 1) + 1);

    let linewidth = Math.round(
      Math.random() * (lineMaxWidth - lineMinWidth) + lineMinWidth
    );

    // Create the SVG element
    line.setAttributeNS(null, "x1", x1.toString());
    line.setAttributeNS(null, "y1", y1.toString());
    line.setAttributeNS(null, "x2", (x1 + linewidth).toString());
    line.setAttributeNS(null, "y2", (y1 + 0.0001).toString());
    line.setAttributeNS(null, "stroke-width", "5");
    line.setAttributeNS(null, "class", "line");
    line.setAttributeNS(null, "stroke-linecap", "round");
    line.setAttributeNS(null, "stroke-dasharray", "0");
    line.setAttributeNS(null, "stroke", "url(#grad" + gradient + ")");
    line.setAttributeNS(null, "style", "opacity:0");

    let dashArray = [
      "0 10 0 10 500",
      "0 10 500",
      linewidth - 30 + " 10 0 10 0 500",
      linewidth - 30 + "10 0 500",
      "0",
      "0",
      "0",
      "0",
    ];

    line.setAttributeNS(null, "stroke-dasharray", randomElement(dashArray));

    // Create the Timeline
    let tl = gsap.timeline({
      onComplete: function () {
        // remove the line from the DOM at the end of the animation
        line.remove();
        lineTimelines.splice(lineTimelines.indexOf(tl), 1);
      },
    });

    // Add line timelines to the array to access it out
    lineTimelines.push(tl);

    // Define the animation
    let time = Math.random() * (3 - 1) + 1;
    tl.to(line, time, {
      opacity: 1,
      x: 50,
      ease: "power0.easeNone",
    }).to(line, time, {
      opacity: 0,
      x: 100,
      ease: "power0.easeNone",
    });

    // Insert the line in the DOM
    container.insertBefore(line, container.firstChild);

    // Create another line, sometimes...
    if (!end) {
      let offset = Math.round(Math.random() * (30 - -30) + -30);
      if (Math.random() * 10 > 3)
        createGradientLine(container, true, x1 + offset, y1 + 10, gradient);
    }
  }

  function resizeViewBox(viewBox) {
    let headerAnimationBase = document.querySelector(".header-animation-base");
    viewBoxWidth = window.innerWidth;
    if (window.innerWidth < 640) {
      viewBoxHeight = headerAnimationBase?.clientHeight || 0;
    } else {
      viewBoxHeight = 540;
    }
    viewBox.setAttribute(
      "viewBox",
      "0 0 " + viewBoxWidth + " " + viewBoxHeight
    );
  }

  function pauseLines() {
    for (let i = 0; i < lineTimelines.length; i++) {
      lineTimelines[i].pause();
    }
  }

  function playLines() {
    for (let i = 0; i < lineTimelines.length; i++) {
      lineTimelines[i].play();
    }
  }

  function togglePause() {
    isPaused = !isPaused;
    if (isPaused) {
      pauseLines();
    } else {
      playLines();
    }
  }

  function handleVisibilityChange() {
    // handling visibility changes
    if (document.hidden) {
      pauseLines();
    } else {
      playLines();
    }
  }

  function randomElement() {
    const array = [];
    return array[Math.floor(Math.random() * array.length)];
  }

  function toggleScrollDown() {
    let scrollDownArrow = document.querySelector(".please-scroll-down");
    if (scrollDownArrow) {
      if (window.scrollY > 0) {
        scrollDownArrow.style.opacity = "0";
      } else {
        scrollDownArrow.style.opacity = "1";
      }
    }
  }

  function daysUntilEvent(eventDate) {
    const today = currentDate;
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round((eventDate - today) / oneDay);
    return diffDays;
  }

  function init() {
    if (typeof localStorage !== 'undefined') {
    adminMode = localStorage.getItem("adminMode");
    } else {
      adminMode = "false";
    }
    if (adminMode === "true") {
      // Get theme from local storage
      const adminTheme = localStorage.getItem("adminTheme");
      if (adminTheme) {
        setTheme(adminTheme);
      } else {
        setTheme("theme-default");
      }
    }
    // Uncomment this to enable automatic theme switching
    // else {
    //   let foundTheme = false;
    //   for (let themeName in themes) {
    //     let theme = themes[themeName];
    //     if (
    //       theme.date &&
    //       daysUntilEvent(theme.date) >= 0 &&
    //       daysUntilEvent(theme.date) <= 15
    //     ) {
    //       setTheme(themeName);
    //       foundTheme = true;
    //       break;
    //     }
    //   }
    //   if (!foundTheme) {
    //     setTheme("theme-default");
    //   }
    // }

    circlesGroups = document.querySelectorAll(".group-header");

    let headerAnimationBackground = document.querySelector(
      ".header-animation-background"
    );

    resizeViewBox(headerAnimationBackground);
    window.addEventListener("resize", () => {
      resizeViewBox(headerAnimationBackground);
    });
    document.addEventListener(
      "visibilitychange",
      handleVisibilityChange,
      false
    );
    document.addEventListener("scroll", toggleScrollDown, false);

    // lines animation
    setInterval(function () {
      const isMobile = /Mobi|Android/i.test(navigator.userAgent);

      if (document.hidden || isMobile) return;
      if (document.hidden) return;
      // if (pause) return;

      let linesToGenerate = Math.round(Math.random() * 1);
      for (let i = 0; i <= linesToGenerate; i++) {
        const animationFunction = themes[currentTheme].animation;
        animationFunction(headerAnimationBackground);
      }
    }, 1000);
  }

  onMount(async () => {
    init();
    hoveredLink = Scrambler({
      target: "[data-scrambler]",
      random: [500, 700],
      speed: 100,
      text: title,
    });
  });

  let hoveredLink = title;

  function handleMouseEnter(event) {
    hoveredLink = event.target.getAttribute("aria-label").replace("/", "");
    Scrambler({
      target: "[data-scrambler]",
      random: [500, 700],
      speed: 100,
      text: hoveredLink,
    });
  }

  function handleMouseLeave() {
    setTimeout(() => {
      document.querySelector(".big-title").innerText = title;
    }, 500);
  }
</script>

<div class="header-animation-container relative">
  <div
    class="absolute w-screen top-[188px] sm:top-[250px] z-[40] h-px my-4 border-b border-gray-700"
    ></div>
  <svg
    class="header-animation-background absolute inset-0 w-screen h-3/4 md:h-full bg-neutral-950"
    preserveAspectRatio="xMidYMid slice"
    viewBox="0 0 960 540"
  >
    <line x1="0" y1="50%" x2="100%" y2="50%" class="header-transbar" />
    <defs>
      <linearGradient x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stop-color="#ff4c7c" />
        <stop offset="50%" stop-color="#0099ff" />
        <stop offset="100%" stop-color="#99cb33" />
      </linearGradient>
      <linearGradient id="grad1">
        <stop offset="0%" stop-color="#ff84d8" />
        <stop offset="100%" stop-color="#ff4d7d" />
      </linearGradient>
      <linearGradient id="grad2">
        <stop offset="0%" stop-color="#00e5ff" />
        <stop offset="100%" stop-color="#0099ff" />
      </linearGradient>
      <linearGradient id="grad3">
        <stop offset="0%" stop-color="#e5f259" />
        <stop offset="100%" stop-color="#9acc33" />
      </linearGradient>
      <linearGradient x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stop-color="#ff4c7c" />
        <stop offset="50%" stop-color="#0099ff" />
        <stop offset="100%" stop-color="#99cb33" />
      </linearGradient>
    </defs>
  </svg>
  <div
    class="row header-animation-page header-animation-home flex justify-center md:pb-10 bg-neutral-950"
  >
    <div
      class="header-animation-base z-50 flex flex-col-reverse md:flex-row justify-between max-w-6xl mt-7 items-center sm:gap-4 md:gap-0 lg:gap-5 lg:px-6"
    >
      <div
        class="flex flex-col items-center md:items-start md:pb-8 md:pl-4 lg:pl-0"
      >
        <h1
          class="big-title pt-6 md:pt-0 pb-2 md:pb-4 capitalize md:text-start text-4xl lg:text-[44px] xl:text-5xl whitespace-nowrap font-black w-80 md:w-64 lg:w-80 xl:w-96 text-center animate-background from-[#ff4c7c] via-[#09f] to-[#99cb33] bg-[length:_400%_400%] bg-gradient-to-r bg-clip-text text-transparent"
          data-scrambler
        >
          {hoveredLink}
        </h1>
        <div
          class="hidden small-gradient sm:flex flex-row gap-1 self-center md:self-start mb-2.5"
        >
          <div class="h-1.5 w-5 bg-bubblegum" ></div>
          <div class="h-1.5 w-2 bg-cobalt" ></div>
          <div class="h-1.5 w-2 bg-kiwi" ></div>
        </div>
        <div
          class="h-12 flex flex-row gap-4 sm:gap-2.5 items-center md:p-0 justify-center sm:justify-start md:mt-2 text-gray-400 mb-6 sm:mb-0"
        >
          <slot name="socialLinks" />
        </div>
      </div>
      <div class="flex flex-col w-screen sm:w-auto items-center">
        <div class="flex flex-row items-center gap-10">
          <div
            class="rounded-full h-80 w-80 sm:w-[480px] sm:h-[480px] bg-transparent relative border-dashed border-slate-600/50 border"
          >
            <div
              class="rounded-full h-72 w-72 sm:w-[420px] sm:h-[420px] bg-transparent absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-dashed border-slate-600/50 border"
            >
              <div class="flex flex-col gap-44 sm:gap-60 items-center">
                <div class="flex flex-row gap-14 sm:gap-20">
                  <div
                    class="mt-14 w-8 sm:w-12 z-50 hover:cursor-pointer before:text-[6px] before:mt-3 before:content-['⚪'] before:absolute before:top-8 before:left-10 sm:before:top-11 sm:before:left-14"
                  >
                    <a href="/" aria-label="Home"> </a>
                  </div>
                  <div
                    class="mt-4 sm:mt-6 w-8 sm:w-12 z-50 hover:cursor-pointer before:text-[6px] before:mt-3 before:content-['⚪'] before:absolute before:-top-5 before:left-1/2"
                  >
                    <a
                      href="/#"
                      aria-label="????"
                      on:mouseenter={handleMouseEnter}
                      on:mouseleave={handleMouseLeave}
                    >
                      <svg viewBox="0 0 56 56" class="group">
                        <path
                          class="crypt group-hover:fill-fuchsia-700 group-hover:stroke-fuchsia-700"
                          transform="scale(1.75) translate(5, 2)"
                          fill="#FFF"
                          stroke="#FFF"
                          d="M18.29.38a7.55,7.55,0,0,1,2.63.82c.38.21.47.64.3,1.28S20.78,3.87,20.4,5s-.8,2.28-1.25,3.37l-.94,2.27a20.85,20.85,0,0,0,2.85.25l2.21.06c.21.06.31.15.28.26s-.18.23-.46.33a13.41,13.41,0,0,1-1.39.36c-.49.1-1.07.2-1.74.29l-2.32.31a36,36,0,0,1-2.52,4.89,23,23,0,0,1-2.35,3.24c-.65.69-1.13,1-1.45.93s-.15-.67.13-1.5q.18-.33.75-1.83c.38-1,.71-2,1-2.91s.54-1.81.74-2.58c-1.18.08-2.82.09-4.9.06s-3.92-.13-5.52-.26a21.59,21.59,0,0,1-3-.36A.56.56,0,0,1,0,11.68a8.31,8.31,0,0,1,.07-1.44,2.36,2.36,0,0,1,.4-1.19.42.42,0,0,1,.46-.18c.18,0,1.38.17,3.61.47s3.7.47,4.39.5c0-.6,0-1.46,0-2.58s.07-2.5.17-4.15A13,13,0,0,0,6.8,2.64C6,2.56,5.48,2.48,5.16,2.43s-.34-.38,0-.74A4.67,4.67,0,0,1,7.07.7,14.22,14.22,0,0,1,10.3.1,36,36,0,0,1,14.38,0,30.21,30.21,0,0,1,18.29.38ZM12.85,3.66a11.28,11.28,0,0,0-.55,3.09c-.15,1.59-.22,2.72-.21,3.4.46.06.87.11,1.24.14s.83.05,1.38.06c.3-.71.65-1.72,1.07-3a20.78,20.78,0,0,0,.75-2.91A4.77,4.77,0,0,0,15,3.89,11.07,11.07,0,0,0,12.85,3.66Z"
                        />
                      </svg>
                    </a>
                  </div>
                  <div
                    class="mt-[52px] sm:mt-20 w-8 sm:w-12 z-50 hover:cursor-pointer before:text-[6px] before:mt-3 before:content-['⚪'] before:absolute before:top-8 before:right-10 sm:before:top-11 sm:before:right-14"
                  >
                    <a
                      href="/"
                      aria-label="Home"
                      class="group"
                      on:mouseenter={handleMouseEnter}
                      on:mouseleave={handleMouseLeave}
                    >
                      <svg viewBox="0 0 56 56">
                        <path
                          class={`crypt ${
                            path === "/"
                              ? "fill-theme stroke-theme"
                              : "fill-white stroke-white group-hover:fill-fuchsia-700 group-hover:stroke-fuchsia-700"
                          }`}
                          transform="scale(1.75) translate(5, 2)"
                          fill="#FFF"
                          stroke="#FFF"
                          d="M11.53.68A6.89,6.89,0,0,1,14.09,3,5.66,5.66,0,0,1,15,5.68a12.69,12.69,0,0,1-.1,2.66c.35,0,1,.05,1.79.07a16.77,16.77,0,0,0,2.74-.18c.41,0,.49.17.23.52a5.07,5.07,0,0,1-2,.94,23,23,0,0,1-3,.65,6.57,6.57,0,0,1-.74,2.09,25.57,25.57,0,0,1-1.43,2.65,20,20,0,0,1-1.25,1.8,3.37,3.37,0,0,1,1,.22,7.73,7.73,0,0,1,1.05.55,5.76,5.76,0,0,1,1.23,1.12,3.79,3.79,0,0,1,.81,2,7.14,7.14,0,0,1,0,2.18c-.15.19-.63.28-1.45.27a2.77,2.77,0,0,1-1.42-.21,1.75,1.75,0,0,1,0-.95,3.84,3.84,0,0,0-.4-2,1.94,1.94,0,0,0-2-1.21,3.4,3.4,0,0,0-2.49.95,3.53,3.53,0,0,0-1.17,2,2.56,2.56,0,0,1-.35,1.22c-.18.16-.64.18-1.38.08a9.51,9.51,0,0,1-1.2-.2.5.5,0,0,1-.18-.58,6.34,6.34,0,0,1,.82-1.86,8.35,8.35,0,0,1,1.37-1.66,5.4,5.4,0,0,1,1.53-1.08,3.88,3.88,0,0,0,1-.63c.13-.16.35-.49.68-1A19.59,19.59,0,0,0,9.93,14a14.46,14.46,0,0,0,1-3.1,22.08,22.08,0,0,1-2.21.16H5c-1.74,0-3.22-.06-4.45-.16A.56.56,0,0,1,0,10.51a9.06,9.06,0,0,1,0-1.46A5.74,5.74,0,0,1,.25,8a.57.57,0,0,1,.47-.35,9.9,9.9,0,0,1,2.62.12c1.51.16,3.17.26,5,.29s2.85.06,3.17.09a3.55,3.55,0,0,0,.12-1.83,6.38,6.38,0,0,0-.65-2.09,2.72,2.72,0,0,0-1.7-1.34A4.48,4.48,0,0,0,6.9,2.75a7.43,7.43,0,0,0-2.07.77A7.32,7.32,0,0,0,3.18,4.73c-.47.46-.78.68-.94.68a3.75,3.75,0,0,1-.64-.9,3.69,3.69,0,0,1-.47-1.2,1.46,1.46,0,0,1,.51-.78A11.47,11.47,0,0,1,3.3,1.29,10.13,10.13,0,0,1,7.15.12,6.72,6.72,0,0,1,11.53.68Z"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <div
                  class="flex flex-row items-center w-full justify-between h-full absolute top-0 p-3"
                >
                  <div
                    class="w-8 sm:w-12 hover:cursor-pointer before:text-[6px] before:-ml-1.5 before:mt-2.5 before:content-['⚪'] before:absolute before:left-0"
                  >
                    <a
                      href="/about"
                      aria-label="About Me"
                      class="group"
                      on:mouseenter={handleMouseEnter}
                      on:mouseleave={handleMouseLeave}
                    >
                      <svg viewBox="0 0 56 56">
                        <path
                          class={`crypt ${
                            path.includes("about")
                              ? "fill-theme stroke-theme"
                              : "fill-white stroke-white group-hover:fill-fuchsia-700 group-hover:stroke-fuchsia-700"
                          }`}
                          fill="white"
                          stroke="white"
                          d="M2.05,5.8a5,5,0,0,1,1.76.1c.87.15,2.29.3,4.27.44s4.27.23,6.89.26a36.74,36.74,0,0,0,5-.13c.72-.12,1.16-.13,1.3,0a.57.57,0,0,1-.06.47l-.35.29a10,10,0,0,0-1.29,2.2,26.52,26.52,0,0,1-2.13,3.64,40.25,40.25,0,0,1-2.72,3.56A19.79,19.79,0,0,1,12,19.32l.56.55c.1.12.06.3-.12.52-1.73,1.31-2.79,1.88-3.16,1.7C8.44,21,7.59,19.7,6.72,18.36a36.26,36.26,0,0,1-2.55-4.7A29.82,29.82,0,0,1,2.48,9.39c-.55-.07-1-.14-1.49-.22a2.43,2.43,0,0,1-1-.38C0,8.54.24,8,.81,7.21A5.2,5.2,0,0,1,2.05,5.8ZM2.93,0A16.24,16.24,0,0,1,5.59.26C6.83.45,8.23.6,9.82.71s2.89.21,3.94.27,2.16.1,3.36.1,2.56,0,4.09-.08c.23.07.25.18.06.35a4.51,4.51,0,0,1-1.84,1.1,23.5,23.5,0,0,1-2.26.67,19.08,19.08,0,0,1-2.29.41l-2.16.22a45.12,45.12,0,0,1-5.26.17C6,3.87,4.57,3.78,3.3,3.67A6.43,6.43,0,0,1,1,3.23a6,6,0,0,1,.85-1.86A5,5,0,0,1,2.93,0ZM13.05,9.6c-1.21.08-2.57.1-4.1.06s-2.84-.07-4-.11a19.41,19.41,0,0,0,1.08,1.9q.75,1.2,2,2.94a37.81,37.81,0,0,0,2.48,3,4.94,4.94,0,0,0,1.11-1.05c.35-.44.89-1.2,1.61-2.29a23.31,23.31,0,0,0,1.58-2.66,15,15,0,0,0,.8-2.11A22.87,22.87,0,0,1,13.05,9.6Z"
                          transform="scale(1.75) translate(5, 2)"
                        />
                      </svg>
                    </a>
                  </div>

                  <div
                    class="bg-gradient p-1.5 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-hidden"
                  >
                    <div
                      class="sector absolute rotate-0 origin-top-left skew-x-[44deg] transform w-[5000px] h-[5000px] left-1/2 top-1/2 border-4 border-neutral-950 -mt-5000 -ml-5000"
                      ></div>
                    <div
                      class="sector absolute rotate-[45deg] origin-top-left skew-x-[44deg] transform w-[5000px] h-[5000px] left-1/2 top-1/2 border-4 border-neutral-950 -mt-5000 -ml-5000"
                      ></div>
                    <div
                      class="sector absolute rotate-[90deg] origin-top-left skew-x-[44deg] transform w-[5000px] h-[5000px] left-1/2 top-1/2 border-4 border-neutral-950 -mt-5000 -ml-5000"
                      ></div>
                    <div
                      class="sector absolute rotate-[135deg] origin-top-left skew-x-[44deg] transform w-[5000px] h-[5000px] left-1/2 top-1/2 border-4 border-neutral-950 -mt-5000 -ml-5000"
                      ></div>
                    <div
                      class="sector absolute rotate-[180deg] origin-top-left skew-x-[44deg] transform w-[5000px] h-[5000px] left-1/2 top-1/2 border-4 border-neutral-950 -mt-5000 -ml-5000"
                      ></div>
                    <div
                      class="sector absolute rotate-[225deg] origin-top-left skew-x-[44deg] transform w-[5000px] h-[5000px] left-1/2 top-1/2 border-4 border-neutral-950 -mt-5000 -ml-5000"
                      ></div>
                    <div
                      class="sector absolute rotate-[270deg] origin-top-left skew-x-[44deg] transform w-[5000px] h-[5000px] left-1/2 top-1/2 border-4 border-neutral-950 -mt-5000 -ml-5000"
                      ></div>
                    <div
                      class="sector absolute rotate-[315deg] origin-top-left skew-x-[44deg] transform w-[5000px] h-[5000px] left-1/2 top-1/2 border-4 border-neutral-950 -mt-5000 -ml-5000"
                    ></div>

                    <div class="h-40 w-40 sm:h-52 sm:w-52">
                      <slot name="image" />
                    </div>
                  </div>
                  <div
                    class="w-8 sm:w-12 z-[55] hover:cursor-pointer before:text-[6px] before:-mr-1.5 before:mt-2.5 before:content-['⚪'] before:absolute before:right-0"
                  >
                    <a
                      href="/publications"
                      aria-label="Publications"
                      class="group"
                      on:mouseenter={handleMouseEnter}
                      on:mouseleave={handleMouseLeave}
                    >
                      <svg viewBox="0 0 56 56">
                        <path
                          class={`crypt ${
                            path.includes("publications")
                              ? "fill-theme stroke-theme"
                              : "fill-white stroke-white group-hover:fill-fuchsia-700 group-hover:stroke-fuchsia-700"
                          }`}
                          fill="#FFF"
                          stroke="#FFF"
                          d="M7.84.2A.62.62,0,0,1,8.49,0c.19.09.24.3.17.63a22.64,22.64,0,0,1-.87,2.6C7.3,4.57,6.9,5.79,6.58,6.93S6,9,5.84,9.64c.46,0,1,.07,1.58.14L9.36,10a29.06,29.06,0,0,1,.74-3.22A35.27,35.27,0,0,1,11.4,3c.53-1.29.91-2,1.12-2s.3.13.28.43-.07,1-.17,2-.17,2.09-.2,3.11,0,2.3,0,3.87l2.35.78a13.41,13.41,0,0,1,2.19.95,4.83,4.83,0,0,1,1.26,1,.56.56,0,0,1,.24.41q0,.21-.48.18a15.69,15.69,0,0,0-2.6-.24q-1.5,0-2.91,0c0,.68-.05,1.52,0,2.53s.09,2.15.16,3.42a12.3,12.3,0,0,0,2.13-.38A15.54,15.54,0,0,0,18,17.83,7.5,7.5,0,0,1,20,17c.16,0,.2.25.12.8A4.14,4.14,0,0,1,19.79,19a3.17,3.17,0,0,1-1.14.73c-.62.3-1.57.71-2.86,1.24a27,27,0,0,1-4.47,1.36A24.05,24.05,0,0,1,6.88,23a17.84,17.84,0,0,1-3.44-.17A4.78,4.78,0,0,1,1.14,22,3.76,3.76,0,0,1,0,19.71,14,14,0,0,1,.09,17a20,20,0,0,1,.5-2.47,34.11,34.11,0,0,1,1.22-3.6c.58-1.48,1.1-2.76,1.57-3.85A25.05,25.05,0,0,1,5.31,3.5,21.62,21.62,0,0,1,7.84.2Zm-3,13.41a11,11,0,0,0-.52,1.6A11.94,11.94,0,0,0,4,18a1.88,1.88,0,0,0,1.22,2.08A6.52,6.52,0,0,0,8.71,20a32.87,32.87,0,0,1,.09-3.84Q9,14.73,9,13.44c-.82,0-1.62,0-2.39,0A8.29,8.29,0,0,0,4.83,13.61Z"
                          transform="scale(1.75) translate(5, 2) "
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="flex flex-row gap-14 sm:gap-20">
                  <div
                    class="-mt-14 w-8 sm:w-12 z-50 hover:cursor-pointer before:text-[6px] before:mt-3 before:content-['⚪'] before:absolute before:bottom-10 before:left-10 sm:before:bottom-14 sm:before:left-14"
                  >
                    <a
                      href="/press"
                      aria-label="Press articles"
                      class="group"
                      on:mouseenter={handleMouseEnter}
                      on:mouseleave={handleMouseLeave}
                    >
                      <svg viewBox="0 0 56 56">
                        <path
                          class={`crypt ${
                            path.includes("press")
                              ? "fill-theme stroke-theme"
                              : "fill-white stroke-white group-hover:fill-fuchsia-700 group-hover:stroke-fuchsia-700"
                          }`}
                          fill="#FFF"
                          stroke="#FFF"
                          d="M10.3,0c.27-.09.87.1,1.79.58a4.74,4.74,0,0,1,1.56,1c0,.3-.28,1-1,1.94,2.43,0,4.15-.08,5.16-.16s1.78-.14,2.3-.17a12.29,12.29,0,0,0,1.36-.12c.28,0,.3.21.06.53a9.52,9.52,0,0,1-1.61.69c-.77.27-1.63.54-2.59.82s-2,.53-3.09.79-2.22.46-3.38.63a18.47,18.47,0,0,0-2,2.82c-.72,1.22-1.36,2.31-1.92,3.3s-.78,1.66-.67,2,.81.45,2,.25a25.94,25.94,0,0,0,3.43-.81A27.83,27.83,0,0,0,14.87,13a16.72,16.72,0,0,0,1.69-2.58,6.9,6.9,0,0,1,1-1.58.8.8,0,0,1,.82,0,10.9,10.9,0,0,1,1.38,1c.52.42.78.76.79,1s-.37,1-1.27,2.24a38.87,38.87,0,0,1-3.17,3.68,32.31,32.31,0,0,1-3,2.86,24.85,24.85,0,0,1-2.57,1.83,5.1,5.1,0,0,1-2,.87c-.32.1-.36,0-.12-.41a11.46,11.46,0,0,0,1.51-1.56,25,25,0,0,0,1.67-2.2c.5-.74.91-1.4,1.25-2a7.58,7.58,0,0,1-1.65.64,22,22,0,0,1-2.75.51,13.4,13.4,0,0,1-3.18.1,2.6,2.6,0,0,1-2-1.06,2.89,2.89,0,0,1-.36-1.82,10.1,10.1,0,0,1,.93-2.79c.54-1.21,1-2.21,1.48-3A15.88,15.88,0,0,0,6.2,7l-3.28.25C1.19,7.37.25,7.29.1,7A9,9,0,0,1,0,5.26c0-.89.07-1.41.21-1.54S.86,3.54,2,3.58s2.07.09,2.92.11,1.86,0,3,0c.43-.65.89-1.41,1.39-2.3S10.12,0,10.3,0Z"
                          transform="scale(1.75) translate(5, 2) "
                        />
                      </svg>
                    </a>
                  </div>
                  <div
                    class="-mt-4 w-8 sm:w-12 z-50 hover:cursor-pointer before:text-[6px] before:mt-3 before:content-['⚪'] before:absolute before:-bottom-1.5 before:left-1/2"
                  >
                    <a
                      href="/talks"
                      aria-label="Talks"
                      class="group"
                      on:mouseenter={handleMouseEnter}
                      on:mouseleave={handleMouseLeave}
                    >
                      <svg viewBox="0 0 56 56">
                        <path
                          class={`crypt ${
                            path.includes("talks")
                              ? "fill-theme stroke-theme"
                              : "fill-white stroke-white group-hover:fill-fuchsia-700 group-hover:stroke-fuchsia-700"
                          }`}
                          fill="#FFF"
                          stroke="#FFF"
                          d="M4.25.07C4.4-.07,5.71,0,8.17.3c.2,0,.3.11.32.36s0,1.38,0,3.39,0,4.2.11,6.56a10.34,10.34,0,0,1,1.56.34,11.1,11.1,0,0,1,1.93.79c-.36-6.82-.51-10.49-.44-11A5.87,5.87,0,0,1,13.81,1q2.08.42,2.16.72t.22,7c.09,4.43.17,6.87.22,7.31-.1.11-.26.07-.48-.11a3.14,3.14,0,0,0-1.3-.49,2.79,2.79,0,0,0-1.45-.05c-.32.11-.51.12-.57,0L12.36,14a8.86,8.86,0,0,0-1.62-.91,6.47,6.47,0,0,0-2.16-.42c0,1.48.05,2.74.12,3.76s.15,1.88.24,2.58a43,43,0,0,1,5.27,1.54,34.23,34.23,0,0,1,3.27,1.41,5.82,5.82,0,0,1,1.53,1,.47.47,0,0,1,0,.52c-.09.16-.32.22-.68.18a18.89,18.89,0,0,1-2-.44c-1-.25-2.67-.59-5.09-1a55.35,55.35,0,0,0-5.78-.73c-1.43-.08-2.41-.11-2.95-.1s-.82-.13-.83-.43a2.48,2.48,0,0,0-.46-1.21,7,7,0,0,1-.63-1.2q-.21-.54,0-.57a23.91,23.91,0,0,1,2.57.14c.93.09,2,.24,3.07.44-.06-.63-.17-1.35-.32-2.16s-.35-1.93-.6-3.39a3.24,3.24,0,0,0-1.69.37A2.47,2.47,0,0,0,2.61,15C1,15.2.1,15.23,0,15a4.14,4.14,0,0,1,.88-2.5,4.81,4.81,0,0,1,1.64-1.24A12.22,12.22,0,0,1,5,10.58a15.62,15.62,0,0,0-.18-1.84q-.18-1.3-.48-4.38C4.14,2.32,4,1.08,4,.65A.5.5,0,0,1,4.25.07Z"
                          transform="scale(1.75) translate(5, 2)"
                        />
                      </svg>
                    </a>
                  </div>
                  <div
                    class="-mt-14 w-8 sm:w-12 z-50 hover:cursor-pointer before:text-[6px] before:mt-3 before:content-['⚪'] before:absolute before:bottom-10 before:right-10 sm:before:bottom-14 sm:before:right-14"
                  >
                    <a
                      href="/blog"
                      aria-label="Blog"
                      class="group"
                      on:mouseenter={handleMouseEnter}
                      on:mouseleave={handleMouseLeave}
                    >
                      <svg viewBox="0 0 56 56">
                        <path
                          class={`crypt ${
                            path.includes("blog")
                              ? "fill-theme stroke-theme"
                              : "fill-white stroke-white group-hover:fill-fuchsia-700 group-hover:stroke-fuchsia-700"
                          }`}
                          fill="#FFF"
                          stroke="#FFF"
                          d="M5.23.19A9.8,9.8,0,0,1,7.68.83c.11.1.11.35,0,.73a25.37,25.37,0,0,0-.42,3.08Q7,7.16,6.91,10.32a13.47,13.47,0,0,0,2.15,1.55,4.13,4.13,0,0,0,3.19.73,2.53,2.53,0,0,0,1.92-2,3.28,3.28,0,0,0-.68-3,8.19,8.19,0,0,0-1.82-1.77A3.28,3.28,0,0,1,10.72,5a5.43,5.43,0,0,1,.92-1.49,4.61,4.61,0,0,1,1.1-1.08,13.56,13.56,0,0,1,3,3A5.83,5.83,0,0,1,16.89,11c-.56,2-1.64,3.23-3.26,3.55a5.72,5.72,0,0,1-3.55-.08A11.88,11.88,0,0,1,6.8,12.25c0,.7,0,1.84,0,3.45s0,2.93.1,4c.83.13,1.65.28,2.46.44s1.88.42,3.21.75a32.49,32.49,0,0,1,3.13.91l1.48.55a.3.3,0,0,1,.2.46,13.4,13.4,0,0,1-3,.05c-1.45-.08-3.2-.14-5.26-.18s-3.84,0-5.32,0-2.46.08-2.93.13H.41A.66.66,0,0,1,0,22.57a1.78,1.78,0,0,1,.45-1.1,1.86,1.86,0,0,0,.34-1.41c-.07-.56,0-.85.15-.89a10.45,10.45,0,0,1,1.72.09L5,19.52c-.13-.55-.36-1.78-.69-3.7s-.62-4-.86-6.36S3.07,5.21,3,3.7,3,1,3.05.12C3.22-.06,4,0,5.23.19Z"
                          transform="scale(1.75) translate(5, 2)"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden justify-center ml-4 md:flex">
          <svg viewBox="0 0 42 42" class="h-8 w-auto overflow-visible z-50">
            <path
              stroke="white"
              fill="white"
              class="animate-bounce-slow please-scroll-down"
              d="M30.15,5.62,16.34,19.41a1.18,1.18,0,0,1-1.68,0L.85,5.62a1.21,1.21,0,0,1,0-1.7L3.94.85a1.18,1.18,0,0,1,1.68,0l9.88,9.89L25.38.85a1.18,1.18,0,0,1,1.68,0l3.09,3.07a1.21,1.21,0,0,1,0,1.7Z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class={`${adminMode ? "block" : "hidden"} bottom-0 right-0 fixed p-6 bg-neutral-950/70 backdrop-blur-sm rounded-sm z-[1000]`}
>
  <div class="flex justify-between items-start gap-2 w-full">
    <h3 class="text-white text-center mb-2">Theme Selector</h3>
    <button
      aria-label="theme selector"
      class="mt-1"
      on:click={() => {
        adminMode = false;
        localStorage.removeItem("adminMode");
      }}
    >
      <svg
        fill="white"
        stroke="white"
        width="12"
        height="12"
        data-loading="lazy"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="https://www.w3.org/1999/xlink"
        viewBox="0 0 20 20"
        data-id="svg-loader_28"
      >
        <path d="M2,2 L18,18" stroke-width="3"></path>
        <path d="M10,10 L10,10" stroke-width="3"></path>
        <path d="M2,18 L18,2" stroke-width="3"></path>
      </svg>
    </button>
  </div>
  <select
    class="text-white font-bold py-2 px-4 rounded bg-gradient-to-r from-purple-500 via-purple-600 to-blue-700 bg-purple-600"
    bind:value={currentTheme}
    on:change={(e) => setTheme(e.target.value)}
  >
    <option value="theme-default">Default</option>
    <option value="theme-admin">Admin</option>
    <option value="theme-earth">Earth</option>
    <option value="theme-bastille">Bastille</option>
    <option value="theme-summer">Summer</option>
    <option value="theme-halloween">Halloween</option>
    <option value="theme-chineseny">ChineseNY</option>
    <option value="theme-stpatricks">St. Patrick's</option>
    <option value="theme-winter">Winter</option>
  </select>
</div>
